// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A1X9iH9xT_pLSSCkD__2 {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 354px;
  height: 204px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 1px #000;
  opacity: 1;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GGN4AOj3PiBuS5v1j4V_ {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.dDSFRPuu7ZluIFx6k2W4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.OU05SDFQ33Vi31xi9AOA {
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: -23px;
  color: black;
}

.ilN8J9kHGv_mrsa8y2nC {
  width: 16px;
  margin: 6px;
  height: 16px;
  cursor: pointer;
}

.pm5D4RFnoIWnps_BEUeM {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalResetPassword/modalResetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wBAAwB;EACxB,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".reset_password_block {\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 354px;\n  height: 204px;\n  background-color: #fff;\n  border-radius: 12px;\n  box-shadow: 0 0 1px #000;\n  opacity: 1;\n  z-index: 100;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.reset_password_input {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  padding-bottom: 10px;\n}\n\n.close_modal {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.titleStyle {\n  font-size: 20px;\n  padding-bottom: 10px;\n  margin-top: -23px;\n  color: black;\n}\n\n.visibleIcon {\n  width: 16px;\n  margin: 6px;\n  height: 16px;\n  cursor: pointer;\n}\n\n.button_reset_password_enter {\n  padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset_password_block": `A1X9iH9xT_pLSSCkD__2`,
	"reset_password_input": `GGN4AOj3PiBuS5v1j4V_`,
	"close_modal": `dDSFRPuu7ZluIFx6k2W4`,
	"titleStyle": `OU05SDFQ33Vi31xi9AOA`,
	"visibleIcon": `ilN8J9kHGv_mrsa8y2nC`,
	"button_reset_password_enter": `pm5D4RFnoIWnps_BEUeM`
};
export default ___CSS_LOADER_EXPORT___;
