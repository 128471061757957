var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { setActive } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import { Button } from "./button/Button";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import close_icon from "../../../assets/img/close_modal_icon.png";
import style from "./ModalSuccessAlert.module.css";
var ModalSuccessAlert = function (_a) {
    var isActive = _a.isActive, MessageToUser = _a.MessageToUser;
    var dispatch = useAppDispatch();
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var userName = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c.first_name; });
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    var styles = {
        width: "354px",
        height: "40px",
        backgroundColor: "rgba(20, 130, 197, 0.6)",
        fontSizes: "14px",
        fontWeight: "700",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
    };
    var stylesForMobile = {
        width: "100%",
        height: "40px",
        backgroundColor: "rgba(20, 130, 197, 0.6)",
        fontSizes: "28px",
        fontWeight: "400",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        border: "none",
        cursor: "pointer",
    };
    return (isActive && (_jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.modalSuccessAlert }, { children: _jsxs("div", __assign({ className: style.content }, { children: [_jsxs("div", __assign({ className: style.messageWrapper }, { children: [_jsxs("div", __assign({ className: style.welcomeMessageWrapper }, { children: [(MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.detail) ? (_jsx("div", { dangerouslySetInnerHTML: { __html: MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.detail }, className: style.textContentWrapper })) : (_jsx("div", __assign({ className: style.textContentWrapper }, { children: typeof MessageToUser === "string" && (MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.length) < 500 ? MessageToUser : "Что-то пошло не так" }))), !isMobile ? _jsx("div", { onClick: function () { return handleCloseModal(); }, className: style.modalErrorAlertClose }) : null] })), _jsx("img", { onClick: function () { return handleCloseModal(); }, className: style.modalCloseIcon, src: close_icon, alt: "close icon" })] })), _jsx("div", __assign({ onClick: function () { return handleCloseModal(); }, className: style.buttonWrapper }, { children: _jsx(Button, { onClickFn: function () { return handleCloseModal(); }, title: "Перейти на сайт", styles: isMobile ? stylesForMobile : styles }) }))] })) }))));
};
export default ModalSuccessAlert;
