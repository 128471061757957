import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { CompanyForm } from "./UserFromDenendsOnRole/CompanyForm";
import { ExpertForm } from "./UserFromDenendsOnRole/ExpertForn";
import { UserForm } from "./UserFromDenendsOnRole/UserForm";
import { DiscontForm } from "./UserFromDenendsOnRole/DiscontForm";
export var UserInfoForm = function () {
    var userRole = useSelector(function (state) { var _a; return (_a = state.auth.userAuthInfo) === null || _a === void 0 ? void 0 : _a.role; });
    switch (userRole) {
        case 1:
            return _jsx(CompanyForm, {});
        case 2:
            return _jsx(ExpertForm, {});
        case 3:
            return _jsx(UserForm, {});
        case 4:
            return _jsx(DiscontForm, {});
    }
};
