var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/store";
import { setCheckedToggleButtons } from "../../../../redux/updateUserReducer";
import style from "./userEditTogglers.module.css";
import { useMediaQuery } from "react-responsive";
export var UserEditTogglers = function () {
    var dispatch = useAppDispatch();
    var toggleButtonsFunc = useSelector(function (state) { return state.updateUser.togglersButtons; });
    var isPlanshet = useMediaQuery({
        query: "(max-width: 670px)",
    });
    var handlerToggleButtons = function (button) {
        dispatch(setCheckedToggleButtons(button));
    };
    return isPlanshet ? (_jsx("div", __assign({ className: style.togglerWrapper }, { children: _jsx("div", __assign({ className: style.scrollableList }, { children: toggleButtonsFunc.map(function (button) { return (_jsx("button", __assign({ className: button.checked === true ? style.nav_active : style.nav, onClick: function () { return handlerToggleButtons(button); } }, { children: button.title }), button.id)); }) })) }))) : (_jsx("div", __assign({ className: style.togglerWrapper }, { children: toggleButtonsFunc.map(function (button) { return (_jsx("button", __assign({ className: button.checked === true ? style.nav_active : style.nav, onClick: function () { return handlerToggleButtons(button); } }, { children: button.title }), button.id)); }) })));
};
