import { jsx as _jsx } from "react/jsx-runtime";
import { UserInfoForm } from "../DifferentUserForm/UserInfoForm";
import { ChangeUserPassword } from "../DifferentUserForm/ChangeUserPassword";
import { ConfigureUserMenu } from "../DifferentUserForm/ConfigureUserMenu";
import { useSelector } from "react-redux";
export var SetttingUserDifferentBlocks = function () {
    var typeBlock = useSelector(function (state) { return state.updateUser.typeChoosedBlock; });
    switch (typeBlock) {
        case "user-info":
            return _jsx(UserInfoForm, {});
        case "change-pass":
            return _jsx(ChangeUserPassword, {});
        case "configure-menu":
            return _jsx(ConfigureUserMenu, {});
    }
};
