var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputAdornment, TextField } from '@mui/material';
import style from './modalResetPassword.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { setActive } from "../../../redux/modalReducer";
import { connect } from "react-redux";
import { RefreshPassword, RefreshPasswordWithEmail } from "../../../redux/authReducer";
import { ModalErrorMaterial } from "../modalErrorMaterial/modalErrorMaterial";
import VisibleOffIcon from '../../../assets/img/VisibleOff.png';
import VisibleOnIcon from '../../../assets/img/VisibleOn.png';
var ModalResetPasswordBlock = function (props) {
    var type = props.type;
    var dispatch = useAppDispatch();
    var _a = useState(''), oldPassword = _a[0], setOldPassword = _a[1];
    var _b = useState(''), newPassword = _b[0], setNewPassword = _b[1];
    var _c = useState(false), isRevealNewPwd = _c[0], setIsRevealNewPwd = _c[1];
    var _d = useState(''), repeatNewPassword = _d[0], setRepeatNewPassword = _d[1];
    var _e = useState(false), isRevealRepeatNewPwd = _e[0], setIsRevealRepeatNewPwd = _e[1];
    var _f = useState(''), email = _f[0], setEmail = _f[1];
    var CloseModal = function () {
        dispatch(setActive(false));
    };
    var SubmitResetPassword = function (oldPassword, newPassword) {
        dispatch(RefreshPassword(oldPassword, newPassword));
    };
    var SubmitEmail = function (email) {
        dispatch(RefreshPasswordWithEmail(email));
    };
    return (props.isActive ?
        _jsxs("div", __assign({ className: style.reset_password_block }, { children: [_jsx(ModalErrorMaterial, {}), _jsx("div", __assign({ className: style.close_modal }, { children: _jsx(Button, __assign({ variant: "text", onClick: function () { CloseModal(); } }, { children: _jsx(CloseIcon, { sx: { color: "#828282", marginTop: '18px' } }) })) })), _jsx("h1", __assign({ className: style.titleStyle }, { children: "\u0421\u0431\u0440\u043E\u0441 \u043F\u0430\u0440\u043E\u043B\u044F " })), _jsx("div", __assign({ className: style.reset_password_input }, { children: type === 'refresh-password-modal'
                        ? _jsxs(_Fragment, { children: [_jsx(TextField, { onChange: function (event) { setOldPassword(event.target.value); }, id: "oldPassword", label: "\u0421\u0442\u0430\u0440\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C", variant: "standard" }), _jsx(TextField, { onChange: function (event) { setNewPassword(event.target.value); }, id: "newPasswords", label: "\u041D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C", variant: "standard", type: isRevealRepeatNewPwd ? "text" : "password", InputProps: {
                                        endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx("img", { className: style.visibleIcon, src: isRevealRepeatNewPwd ? VisibleOnIcon : VisibleOffIcon, onClick: function () { return setIsRevealRepeatNewPwd(function (prevState) { return !prevState; }); } }) })),
                                    } }), _jsx(TextField, { onChange: function (event) { setRepeatNewPassword(event.target.value); }, id: "repeatNewPassword", label: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C", variant: "standard", type: isRevealNewPwd ? "text" : "password", helperText: repeatNewPassword !== newPassword ? 'Пароль не совпадает' : '', error: repeatNewPassword !== newPassword, InputProps: {
                                        endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx("img", { className: style.visibleIcon, src: isRevealNewPwd ? VisibleOnIcon : VisibleOffIcon, onClick: function () { return setIsRevealNewPwd(function (prevState) { return !prevState; }); } }) })),
                                    } })] })
                        : _jsx(_Fragment, { children: _jsx(TextField, { onChange: function (event) { setEmail(event.target.value); }, id: "email", type: 'mail', label: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 email", style: {
                                    width: '300px',
                                    height: '40px',
                                    color: '#828282',
                                } }) }) })), _jsx("div", __assign({ className: style.button_reset_password_enter }, { children: _jsx(Button, __assign({ onClick: function () {
                            if (type === 'refresh-password-modal') {
                                SubmitResetPassword(oldPassword, newPassword);
                            }
                            else {
                                SubmitEmail(email);
                            }
                        }, variant: "contained", sx: {
                            width: '300px',
                            height: '40px',
                            background: 'var(--btn-blue)',
                            textTransform: 'capitalize',
                        } }, { children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" })) }))] }))
        : _jsx(_Fragment, {}));
};
export var ModalResetPassword = connect()(ModalResetPasswordBlock);
