// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UOPBB3lAZjwmKMYXRFJ1 {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 34px 22px 29px 33px;
    width: 424px;
    /* height: 189px; */
    background-color: #fff;
    border-radius: 12px;
    opacity: 1;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.mMddxSE2p5mYtwQXMf9z {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.YBqXeBNbqffXUUP6EMFW {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.Zn8FTtx4aNfxwu1pRRZT {
    color: #000;
    font-size: 16px;
}

@media (max-width: 880px) {
    .YBqXeBNbqffXUUP6EMFW {
        margin-left: 85%;
    }
}

@media (max-width: 480px) {
    .UOPBB3lAZjwmKMYXRFJ1 {
        width: 90%;
    }
    .YBqXeBNbqffXUUP6EMFW {
        margin-left: 83%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalStartupContacts/modalStartupContacts.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,4BAA4B;IAC5B,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".startupContacts {\n    position: fixed;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 34px 22px 29px 33px;\n    width: 424px;\n    /* height: 189px; */\n    background-color: #fff;\n    border-radius: 12px;\n    opacity: 1;\n    z-index: 999;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    overflow: hidden;\n}\n\n.content {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n\n.btnClose {\n    background: none;\n    border: none;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n\n.startupContacts_content {\n    color: #000;\n    font-size: 16px;\n}\n\n@media (max-width: 880px) {\n    .btnClose {\n        margin-left: 85%;\n    }\n}\n\n@media (max-width: 480px) {\n    .startupContacts {\n        width: 90%;\n    }\n    .btnClose {\n        margin-left: 83%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startupContacts": `UOPBB3lAZjwmKMYXRFJ1`,
	"content": `mMddxSE2p5mYtwQXMf9z`,
	"btnClose": `YBqXeBNbqffXUUP6EMFW`,
	"startupContacts_content": `Zn8FTtx4aNfxwu1pRRZT`
};
export default ___CSS_LOADER_EXPORT___;
