var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/store";
import { setConfigurableUserMenu, setPickCheckboxInMenu } from "../../../../redux/updateUserReducer";
import style from "./configurableMenu.module.css";
export var ConfigureUserMenu = function () {
    var arrayForCheckBoxes = useSelector(function (state) { return state.updateUser.arrayForCheckBoxes; });
    var menuList = useSelector(function (state) { return state.auth.userMenuListInProfile; });
    var dispatch = useAppDispatch();
    useEffect(function () {
        dispatch(setConfigurableUserMenu(menuList));
    }, []);
    return (_jsxs("div", __assign({ className: style.wrapper }, { children: [_jsx("p", __assign({ className: style.placeholderForMenu }, { children: "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u0443\u043D\u043A\u0442\u044B \u0434\u043B\u044F \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F \u0432\u00A0\u043C\u0435\u043D\u044E. \u041E\u0441\u0442\u0430\u043B\u044C\u043D\u044B\u0435 \u043F\u0443\u043D\u043A\u0442\u044B \u0431\u0443\u0434\u0443\u0442 \u043D\u0430\u0445\u043E\u0434\u0438\u0442\u044C\u0441\u044F \u0432\u00A0\u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u00AB\u0415\u0449\u0435\u00BB." })), _jsx("div", __assign({ className: style.checkboxWrapper }, { children: arrayForCheckBoxes === null || arrayForCheckBoxes === void 0 ? void 0 : arrayForCheckBoxes.map(function (button, index) {
                    if (button.title !== "Ещё" && button.title !== "Подписки" && button.title !== "Подписчики") {
                        return (_jsx("div", __assign({ className: style.filter__group_topic__checkbox }, { children: _jsxs("label", __assign({ className: style.filter__input_wrap }, { children: [_jsx("input", { id: button.name, onChange: function (e) {
                                            dispatch(setPickCheckboxInMenu(button));
                                        }, className: style.filter__input_checkbox, value: button.id, name: "user-config-menu", type: "checkbox", checked: button.visible }, button.name), _jsx("span", __assign({ className: style.filter__input_fake }, { children: _jsx(AiOutlineCheck, { className: style.hidden_icon }) })), _jsx("span", __assign({ className: style.filter__input_text }, { children: button.title }))] })) })));
                    }
                }) }))] })));
};
